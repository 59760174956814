<template>
<div class="w-100">
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col">
          <h6>DETALLES DEL TICKET</h6>
          <h6 class="text-danger" v-if="ticket">Esta solucionado? {{ticket.isSolved ? "SI": "NO"}}</h6>
        </div>
      </div>
    </div>
    <div class="card-body px-4">
      <div class="row">
        <div class="col-lg-6" v-if="ticket">
          <div class="card mb-2">
            <div class="card-body">
              <h6 class="card-title">Asunto: <span>{{ ticket.subject }}</span></h6>
              <p class="text-justify"><b>Mensaje:</b> <span>{{ ticket.body }}</span></p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
              <label>Responder ticket</label>
              <textarea v-model="ticket_response" class="form-control" rows="10"></textarea>
              <button class="btn btn-outline-success w-100 mt-2" @click="replyTicket">RESPONDER TICKET</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <h6 class="card-title">Respuestas:</h6>
      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <thead>
          <tr>
            <th>Respuesta</th>
            <th>Fecha</th>
          </tr>
          </thead>
          <tbody v-if="ticket">
          <tr v-for="(item, index) in ticket.answers" :key="index">
            <td :style="{'color': isMe(item) ? '#172b4d' : ''}" class="pb-0 mb-0">
              <div class="limited-width_70">
                {{item.answer}}
              </div>
            </td>
            <td :style="{'color': isMe(item) ? '#172b4d' : ''}" class="pb-0 mb-0">{{ $filters.formatDate(item.created_at) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import {get, post} from "@/utils/httpRequest";

  export default {
    data: () => ({
      ticket_id: null,
      ticket: null,
      ticket_response: null,
      admin_id: null
    }),
    mounted() {
      this.ticket_id = this.$route.query.id
      const store = localStorage.getItem("migration-admin-uuid")
      this.admin_id = store
      this.getTicket()
    },
    methods: {
      async getTicket() {
        const {data} = await get(`support/view?id=${this.ticket_id}`)
        this.ticket = data[0]
      },
      async replyTicket () {
        if(this.ticket_response.value !== null){
          await post("support/answer", {answer: this.ticket_response,support_id: this.ticket_id, isSolved: true })
          this.ticket_response = null
          await this.getTicket()
        }
      },

       isMe(message) {
    return this.admin_id.value === message.user_id;
  }
    }
  }
</script>
